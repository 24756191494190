.logo {
    transition: opacity 0.3s ease-in-out;
}

.logo.show {
    opacity: 1;
}

.logo.hide {
    opacity: 0;
    display: none;
}